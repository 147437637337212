<template>
  <!-- 서브페이지 내용 -->
  <div class="con-wrap">
    <VehicleReservationViewPopup
      :idx="pop.idx"
      :tdate="pop.tdate"
      v-if="pop.isShowSchedule"
      @close="pop.hideSchedule"
    ></VehicleReservationViewPopup>
    <VehicleReservationGuidePopup
      v-if="pop.isShowGuide"
      @close="pop.isShowGuide = false"
    ></VehicleReservationGuidePopup>

    <CarrotTitle title="차량운행 예약하기">
      <div class="btn-ibbox title-btnbox float-right">
        <button @click="pop.showGuide" class="btn-default">
          차량관제 시스템 이용가이드
        </button>
      </div>
      - 업무용 차량은 <span class="color-1">개인용도로 사용할 수 없습니다.</span
      ><br />
      - 차량 이용 시, 총무팀에 비치된
      <span class="color-1">운행일지를 반드시 기록</span>하여 주시기
      바랍니다.<br />
      -
      <span class="color-1"
        >주말에 차량 이용 시 전자결재로 차량 사용 신청서</span
      >를 올려주세요. (전자결재 > 복리후생 > 차량 사용 신청서)
    </CarrotTitle>

    <div class="board">
      <div class="mt-40">
        <div>
          <div class="day-select">
            <button @click="bbs.doPrev" class="btn btn-prev">prev</button>
            <carrot-date-picker
              @change="bbs.doSearch"
              v-model="bbs.today"
              class="inline-block w-160px"
            ></carrot-date-picker>
            <button @click="bbs.doNext" class="btn btn-next">next</button>
          </div>

          <div class="dp-table w-100per mt-40 mb-20">
            <!-- <button @click="bbs.showMonth" class="btn-default float-left w-70px">월</button> -->
            <select
              v-model="bbs.stype"
              @change="bbs.changePage()"
              class="btn-default float-left w-70px"
            >
              <option value="daily">일</option>
              <option value="monthly">월</option>
            </select>
            <button
              @click="bbs.addSchedule('0', '')"
              class="btn-default float-right"
            >
              차량 예약
            </button>
          </div>

          <div class="dayTable-box">
            <div class="day-time">
              <p v-for="(time, i) in bbs.time_labels" :key="i">{{ time }}</p>
            </div>
            <div class="div-dayTable">
              <div class="colgroup">
                <div
                  v-for="i in bbs.list"
                  :key="i"
                  :style="{ width: 1154 / bbs.list.length + 'px' }"
                  class="col"
                ></div>
              </div>
              <div class="thead">
                <div class="tr">
                  <div v-for="(irow, i) in bbs.list" :key="i" class="cell th">
                    <p class="tit">
                      <span
                        v-html="i + 1 + '.' + irow.cname.replace('\n', '<br>')"
                      ></span>
                    </p>
                    <p class="txt">({{ irow.cnum }})</p>
                  </div>
                </div>
              </div>
              <div class="tbody">
                <!-- 30분간격 행 loop -->
                <template v-for="(time, i) in bbs.time_labels">
                  <div :key="i" v-if="i < bbs.time_count" class="tr">
                    <!-- 차량별 loop -->
                    <div v-for="(irow, i) in bbs.list" :key="i" class="cell td">
                      <!-- 예약이 없는경우 <button class="click"></button> -->
                      <button
                        @click="bbs.addSchedule(irow.idx, time)"
                        class="click"
                      ></button>
                      <!-- 예약이 있는경우 
                                                예약한 분이 30분 이하일때 top은 7px 고정값, height 55px 고정값
                                                예약한 분이 30분 이상일때 top은 35px 고정값, height는 70px*(3타임-1) 변동값
                                                예시 : 7시 00분 시작기준으로 3타임(1타임당 30분)일때 : 70px*(3타임-1)
                                            -->
                      <!-- <button @click="pop.showSchedule" class="click has" style="top:35px; height: calc( 70px * 2 );">Heather K<br>(7:00~08:30)</button> -->
                      <!-- //예약이 있는경우 -->
                    </div>
                  </div>
                </template>
                <!-- //30분간격 행 loop -->
              </div>
              <div class="reserve-body">
                <div
                  v-for="(irow, i) in bbs.list"
                  :key="i"
                  class="reserve-cell"
                  :style="{ width: 1154 / bbs.list.length + 'px' }"
                >
                  <!-- 예약이 있는경우 
                                        예약한 분이 30분 이하일때 top은 7px 고정값, height 55px 고정값
                                        예약한 분이 30분 이상일때 top은 35px 고정값, height는 70px*(3타임-1) 변동값
                                        예시 : 7시 00분 시작기준으로 3타임(1타임당 30분)일때 : 70px*(3타임-1)
                                    -->
                  <div
                    v-for="(jrow, j) in irow.list"
                    :key="j"
                    :style="{
                      top: jrow.top + 'px',
                      height: jrow.height + 'px',
                    }"
                    class="reserve-bg"
                  >
                    <button
                      @click="pop.showSchedule(jrow.idx)"
                      class="btn-reserve"
                    >
                      {{ jrow.ename }}<br />({{
                        jrow.stime + "~" + jrow.etime
                      }})
                    </button>
                  </div>
                  <!-- //예약이 있는경우 -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";
import CarrotDatePicker from "@/components/common/CarrotDatePicker.vue";
import VehicleReservationViewPopup from "@/components/popup/ResourceManagement/VehicleReservationViewPopup.vue";
import VehicleReservationGuidePopup from "@/components/popup/ResourceManagement/VehicleReservationGuidePopup.vue";

export default {
  layout: "ResourceManagement",
  components: {
    CarrotDatePicker,
    VehicleReservationViewPopup,
    VehicleReservationGuidePopup,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();

    const pop = reactive({
      idx: 0,
      tdate: "",
      // Popup 노출 여부
      isShowSchedule: false,
      isShowGuide: false,

      showGuide: () => {
        // let href = "http://old.carrotians.net/intranet_snew/car_guide_pop.html";
        // window.open(href, '_blank').focus();
        pop.isShowGuide = true;
      },

      showSchedule: (idx) => {
        pop.idx = idx;
        pop.tdate = bbs.today;
        pop.isShowSchedule = true;
      },
      hideSchedule: () => {
        pop.isShowSchedule = false;
        pop.idx = 0;
        bbs.doSearch();
      },
    });

    const bbs = reactive({
      date_labels: [],
      time_labels: [],
      time_count: 0,

      today: "",
      stype: "daily",

      list: [],
      total: 0,

      doPrev: () => {
        let td = new Date(bbs.today);
        let nd = new Date(td.getFullYear(), td.getMonth(), td.getDate() - 1);
        bbs.today = [
          nd.getFullYear(),
          nd.getMonth() > 8 ? nd.getMonth() + 1 : "0" + (nd.getMonth() + 1),
          nd.getDate() > 9 ? nd.getDate() : "0" + nd.getDate(),
        ].join("-");
        bbs.doSearch();
      },

      doNext: () => {
        let td = new Date(bbs.today);
        let nd = new Date(td.getFullYear(), td.getMonth(), td.getDate() + 1);
        bbs.today = [
          nd.getFullYear(),
          nd.getMonth() > 8 ? nd.getMonth() + 1 : "0" + (nd.getMonth() + 1),
          nd.getDate() > 9 ? nd.getDate() : "0" + nd.getDate(),
        ].join("-");
        bbs.doSearch();
      },

      changePage: () => {
        if (bbs.stype == "monthly") {
          bbs.showMonth();
        }
      },

      showMonth: () => {
        sessionStorage.setItem(
          "VehicleReservationLogDay",
          JSON.stringify({
            today: bbs.today,
          })
        );
        router.push({
          name: "ResourceManagement-VehicleReservationLogMonth",
        });
      },

      addSchedule: (idx_vehicle, time) => {
        sessionStorage.setItem(
          "VehicleReservationLogDay",
          JSON.stringify({
            today: bbs.today,
          })
        );
        if (idx_vehicle > 0) {
          sessionStorage.setItem(
            "VehicleReservationAdd",
            JSON.stringify({
              today: bbs.today,
              time: time,
              idx_vehicle: idx_vehicle,
            })
          );
        } else {
          sessionStorage.removeItem("VehicleReservationAdd");
        }
        router.push({
          name: "ResourceManagement-VehicleReservationAdd",
        });
      },

      makeTimeSchedules: () => {
        let nd = new Date(bbs.today);
        let td = new Date(
          nd.getFullYear(),
          nd.getMonth(),
          nd.getDate(),
          7,
          0,
          0
        );

        for (let i = 0; i < bbs.list.length; i++) {
          let irow = bbs.list[i];

          for (let j = 0; j < irow.list.length; j++) {
            let jrow = irow.list[j];

            jrow.top = 0;
            jrow.height = 0;

            let s = 0;
            for (
              let k = td.getTime(), l = 0;
              k < td.getTime() + 3600 * 1000 * 15;
              k += 1800 * 1000, l++
            ) {
              let kd = new Date(k);

              let ktime =
                (kd.getHours() < 10 ? "0" + kd.getHours() : kd.getHours()) +
                ":" +
                (kd.getMinutes() < 10
                  ? "0" + kd.getMinutes()
                  : kd.getMinutes());

              if (jrow.stime == ktime) {
                s = l;
              }
              if (jrow.stime <= ktime && ktime < jrow.etime) {
                jrow.height += 70;
              }
            }

            jrow.top = s * 70;

            irow.list[j] = jrow;
          }
        }
      },

      doSearch: () => {
        let params = {
          tgdate: bbs.today,
        };
        axios
          .get("/rest/resourceManagement/getVehicleReserveDate", {
            params: params,
          })
          .then((res) => {
            if (res.data.err == 0) {
              bbs.list = res.data.list;
              bbs.total = res.data.total;

              bbs.makeTimeSchedules();
            } else {
              if (res.data.err_msg) toast.error(res.data.err_msg);
            }
          });
      },

      makeTimeLabels: () => {
        for (let i = 7; i < 23; i++) {
          if (i < 10) {
            bbs.time_labels.push("0" + i + ":00");
            bbs.time_labels.push("0" + i + ":30");
          } else if (i == 22) {
            bbs.time_labels.push(i + ":00");
          } else {
            bbs.time_labels.push(i + ":00");
            bbs.time_labels.push(i + ":30");
          }
        }

        bbs.time_count = bbs.time_labels.length - 1;
      },
    });

    onMounted(() => {
      // Mounted
      let nd = new Date();
      let today =
        nd.getFullYear() +
        "-" +
        (nd.getMonth() > 8 ? nd.getMonth() + 1 : "0" + (nd.getMonth() + 1)) +
        "-" +
        (nd.getDate() > 9 ? nd.getDate() : "0" + nd.getDate());
      bbs.today = today;

      let ss = sessionStorage.getItem("VehicleReservationLogDay");
      if (ss) {
        ss = JSON.parse(ss);
        if (ss.today) bbs.today = ss.today;
      }

      bbs.makeTimeLabels();

      bbs.doSearch();
    });

    return { pop, bbs };
  },
};
</script>

<style lang="scss" scoped>
.dayTable-box {
  width: 1200px;
  .div-dayTable {
    position: relative;
    .reserve-body {
      position: absolute;
      top: 70px;
      left: 0;
      width: 100%;
      .reserve-cell {
        position: relative;
        height: 1px;
        float: left;

        .reserve-bg {
          width: 100%;
          background-color: rgba(0, 0, 0, 0.01);
          position: absolute;
          display: flex;
          justify-content: center;
        }

        .btn-reserve {
          margin: 7px auto;
          border: 0;
          font-size: 13px;
          font-weight: normal;
          line-height: 160%;
          text-align: center;
          color: #fff;
          background-color: #ff854a;
          //   width: calc(100% - 40px);
          width: auto;
          height: calc(100% - 16px);
          padding: 5px;
          border-radius: 5px;
          z-index: 1;
          &:hover {
            background-color: #e7621f;
          }
        }
      }
    }
  }
}
</style>
