<template>
    <div class="carrot-layer-bg">
        <VehicleReservationDelPopup v-model:deleted.sync="pop.is_deleted" :idx="pop.idx" :tdate="pop.tdate" v-if="pop.isShowDelete" @close="pop.hideDSchedule"></VehicleReservationDelPopup>

        <div v-if="pop.isLoaded==true" class="carrot-layer w-700px">
            <div>
                <p class="title">{{ pop.info.cname }} ({{ pop.info.cnum }})</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light">
                <table class="tbl-light-view">
                    <colgroup>
                        <col width="120">
                        <col width="210">
                        <col width="120">
                        <col width="*">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>기간</th>
                            <td>{{ pop.info.sdate }} ~ {{ pop.info.edate }}</td>
                            <th>예약자</th>
                            <td>{{ pop.info.ename }}({{ pop.info.kname }})</td>
                        </tr>
                        <tr>
                            <th>시간</th>
                            <td>{{ pop.info.stime }}~{{ pop.info.etime }}</td>
                            <th>요일</th>
                            <td>{{ pop.info.rday_txt }}</td>
                        </tr>
                        <tr>
                            <th>고객사</th>
                            <td>{{ pop.info.company_kname }}</td>
                            <th>물품유무</th>
                            <td>{{ pop.info.is_item=='Y'?'유':'무' }}</td>
                        </tr>
                        <tr>
                            <th>고객사 주소</th>
                            <td colspan="3">{{ pop.info.zipcode?'(' + pop.info.zipcode + ')':'' }}{{ pop.info.addr + ' ' + pop.info.addr_sub }}</td>
                        </tr>
                        <tr>
                            <th>차량사용용도</th>
                            <td colspan="3">{{ pop.info.special_memo }}</td>
                        </tr>
                        <tr>
                            <th>동승자(동승자 기재 필수)</th>
                            <td colspan="3">{{ pop.info.usage_memo }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="layer-btn mt-10 w-100per dp-table">
                <button @click="pop.onClose" class="btn-default float-left">닫기</button>
                <button v-if="pop.info.is_owner=='Y'" @click="pop.showDSchedule" class="btn-default float-left ml-10">삭제</button>
                <button v-if="pop.info.is_owner=='Y'" @click="pop.showModify" class="btn-default float-right">수정</button>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import VehicleReservationDelPopup from '@/components/popup/ResourceManagement/VehicleReservationDelPopup.vue'

export default {
    name: 'VehicleReservationViewPopup',
    components:{
        VehicleReservationDelPopup
    },
    props: {
        idx: {
            type: [ Number, String ],
            default: 0
        },
        tdate: {
            type: String,
            default: ""
        }
    },
    setup(props, {emit}) {
        const router = useRouter();
        const toast = useToast()

        const pop = reactive({
            isShowDelete : false,
            is_deleted   : false,
            isLoaded     : false,

            idx   : 0,
            tdate : "",
            info  : {},

            showDSchedule : () => {
                pop.isShowDelete = true;
            },

            hideDSchedule : () => {
                pop.isShowDelete = false;
                if( pop.is_deleted == true ){
                    pop.onClose();
                }
            },

            showModify : () => {
                router.push({
                    name : 'ResourceManagement-VehicleReservationMod-idx',
                    params : {
                        idx : pop.idx
                    }
                })
            },

            doSearch : () => {
                let params = {
                    idx : pop.idx
                };
                axios.get('/rest/resourceManagement/getVehicleReserveInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.info = res.data;
                        pop.isLoaded = true;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.idx   = props.idx;
            pop.tdate = props.tdate;

            pop.doSearch();
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
</style>