<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-840px h-760px">
            <div>
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-30 guide-box">
                <img src="@/assets/img/vehicle_guide.jpg" usemap="#Map">
            </div>
            <map name="Map" id="Map">
                <area shape="rect" coords="539,39,765,92" href="https://car.uplus.co.kr/security/login" target="_blank">
                <area shape="rect" coords="58,236,334,255" href="https://car.uplus.co.kr/security/login" target="_blank">
            </map>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'

export default {
    name: 'VehicleReservationGuidePopup',
    components:{
    },
    props: {
    },
    setup(props, {emit}) {
        const pop = reactive({
            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
        });

        return {pop};
    }
}
</script>
<style lang="scss" scoped>
.guide-box {
    height:700px; overflow:hidden; overflow-y:auto;
}
</style>